<template>
  <div class="container">
    <div class="row mt-4">
      <p class="fs-5 fw-bold">
        ООО «Импульс» - многопрофильная, динамично развивающаяся организация.
      </p>
      <p class="fs-6">
        Тридцать лет назад она входила в структуру предприятий бытового
        обслуживания населения, по специализации «Ремонт радиотелевизионной
        аппаратуры».
      </p>
      <p class="fs-6">
        Постепенно пройдя разнообразные и многоуровневые юридические ступени
        периода перестройки и новой России, получив статус общества с
        ограниченной ответственностью, значительно расширила сферу предлагаемых
        услуг населению и юридическим лицам, осуществляющим строительство жилья,
        объектов соцкультбыта – деятельность систем обеспечения безопасности,
        строительство слаботочных сетей.
      </p>
      <p class="fs-6">
        ООО «Импульс» производит монтаж слаботочных сетей, с помощью которых в
        домах обеспечивается работа телевидения, телефонии, интернета,
        домофонизации, систем пожарной и охранной сигнализации,
        автоматизированного учета энергоресурсов, локальных компьютерных сетей,
        систем видеонаблюдения, диспетчеризация лифтового хозяйства, как в давно
        построенных, так и новых жилых домах.
      </p>
      <p class="fs-6">Преимущества работы с нами:</p>
      <table class="table-info col fs-6">
        <tr>
          <td class="table_item col-4">
            <img
              src="@/assets/img/checked.svg"
              width="30"
              height="30"
              alt=""
              class="table_image"
            />
          </td>
          <td class="table_item col-4">
            <img
              src="@/assets/img/checked.svg"
              width="30"
              height="30"
              alt=""
              class="table_image"
            />
          </td>
          <td class="table_item col-4">
            <img
              src="@/assets/img/checked.svg"
              width="30"
              height="30"
              alt=""
              class="table_image"
            />
          </td>
        </tr>
        <tr>
          <td class="table_item col-4">Опыт работы более 20 лет</td>
          <td class="table_item col-4">
            Оперативное и качественное обслуживание оборудования
          </td>
          <td class="table_item col-4">
            Индивидуальный подход к техническому решению каждого заказа
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
.table_item {
  border: 1px solid gray;
  /* width: 33%; */
  color: blue;
}
.table_image {
  margin-top: 5px;
}
</style>
