<template>
  <div class="maintenance_wrapper container mt-4">
    <div v-if="!application" class="maintenance_body row">
      <p class="fs-6">
        Техническое обслуживание домофонного оборудования осуществляется только
        для абонентовООО «Импульс».
      </p>
      <p class="fs-6">
        Работы по разовым заявкам проводятся по мере их поступления диспетчеру.
      </p>
      <p class="fs-6">
        Ежегодно сотрудниками ООО «Импульс» проводятся работы по
        планово-предупредительным работам.
      </p>
      <p class="fs-6">
        Заявка может быть принята только от абонента, не имеющего задолженности
        по абонентской плате.
      </p>
      <p class="maintenance_service-list fs-4 fw-bold">
        Услуги по техническомй обслуживанию включают в себя:
      </p>
      <ul class="maintenance_list fs-6 col">
        <li>
          ремонт / замену приборов и оборудования, вышедших из строя при любых
          обстоятельствах, кроме умышленного повреждения, кражи и других
          непредвиденных обстоятельствах
        </li>
        <li>
          ремонт и восстановление центральной магистрали, разводке по квартирам
        </li>
        <li>ремонт переговорных устройств</li>
      </ul>
      <p class="fs-4 fw-bold">
        Ремонт оборудования, включая стоимость материалов, производится ООО
        «Импульс» за счет абонентской платы.
      </p>
      <p class="fs-5">
        Изготовление ключей, установка переговорного устройства, ремонт
        доводчика в техническое обслуживание не входят.
      </p>
      <p class="fs-5">
        Уважаемые абоненты! Оставляя заявку на ремонт домофонного оборудования,
        найдите возможность обеспечить техническому персоналу ООО «Импульс»
        свободный доступ в квартиру и к межэтажным шкафам.
      </p>
      <p class="fs-5">
        Вы вправе попросить нашего сотрудника предъявить служебное
        удостоверение.
      </p>
      <hr />
    </div>
    <p
      @click="application = !application"
      class="maintenance_application fs-5 fw-bold"
    >
      Подать заявку на ремонт домофона.
    </p>
    <div v-if="application" class="row">
      <p class="fs-6">
        Абоненты ООО «Импульс» могут подать заявку на ремонт домофонного
        оборудования по телефонам: 2-25-86, 8-915-856-07-23, по электронной
        почте <br />
        <a href="mailto:impuls-zayavka@yandex.ru">impuls-zayavka@yandex.ru</a>
      </p>
      <p class="fs-6">
        Прием заявок осуществляется диспетчером с понедельника по субботу с 8 до
        17 часов.
      </p>
      <p class="fs-6">
        Наши клиенты могут быть уверены, что по каждому их обращению
        неисправность домофонного оборудования будет устранена в кратчайшие
        сроки.
      </p>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  setup() {
    const application = ref(false);
    return {
      application,
    };
  },
};
</script>
<style>
.maintenance_service-list {
  /* margin-top: 50px; */
}
.maintenance_list {
  text-align: justify;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}
.maintenance_application {
  cursor: pointer;
  color: blue;
}
.maintenance_application:hover {
  cursor: pointer;
  color: red;
}
</style>
