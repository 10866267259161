<template>
  <iframe
    src="https://yandex.ru/map-widget/v1/?z=12&ol=biz&oid=18072829088"
    width="auto"
    class="col-11 map"
    frameborder="0"
  ></iframe>
</template>
<style>
.map {
  height: 75vh;
}
</style>
