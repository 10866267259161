<template>
  <div class="services_wrapper container">
    <div class="mt-4">
      <p class="services_menu-item fs-4 fw-bold" @click="fizUsers">
        Физическим лицам
      </p>
      <div v-if="fizUser" class="mb-4" >
        <div class="services_menu-nav">
          <span
            class="services_menu-item mb-4" :class="{ }"
            @click="fizMounting = !fizMounting"
            >Монтаж и техническое обслуживание аудио/видео домофонов:</span
          >
          <!-- <div v-if="fizMounting" class="services_list mb-4 px-2 py-1 " :class="{fadeIn: fizMounting,fadeOut: !fizMounting}"> -->
          <div class="services_list mb-4 px-2 py-1 " :class="{fadeIn: fizMounting,fadeOut: !fizMounting}">
            <ul v-for="(item, idx) in fizMountingArray" :key="idx" class="list">
              <li>{{ item }}</li>
            </ul>
          </div>
        </div>
        <div class="services_menu-nav">
          <span class="services_menu-item" @click="fizVideo = !fizVideo"
            >Видеонаблюдение:</span
          >
          <!-- <div v-if="fizVideo" class="services_list  px-2 py-1"> -->
          <div class="services_list  px-2 py-1" :class="{fadeIn: fizVideo,fadeOut: !fizVideo}">
            <ul v-for="(item, idx) in fizVideoArray" :key="idx" class="list">
              <li>{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
      <p class="services_menu-item fs-4 fw-bold" @click="jurUsers">
        Юридическим лицам
      </p>
      <!-- <div v-if="jurUser" class="services_list  px-2 py-1"> -->
      <div class="services_list  px-2 py-1" :class="{fadeIn: jurUser,fadeOut: !jurUser}">
        <ul v-for="(item, idx) in jurMountingArray" :key="idx" class="list">
          <li>{{ item }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
export default {
  setup() {
    const fizUser = ref(false);
    const jurUser = ref(false);
    const fizMounting = ref(false);
    const fizVideo = ref(false);
    const jurMounting = ref(false);
    const fizMountingArray = ref([
      "Подбор оборудования, проектирование систем домофонизации (частное жилье и многоквартирное)",
      "Монтаж и настройка аудио/видео домофонов",
      "NEW установка IP домофонов, с функцией управления со смартфона, включая просмотр видеоизображения, и удаленное открытие двери (в июле-августе 2021 г. было проведено тестирование оборудования для оказания данной услуги, в настоящий момент идет разработка экономической составляющей проекта)",
      "Техническое обслуживание системы домофонизации",
      "Изготовление ключей для домофона (только абонентам ООО «Импульс»)",
      "Установка и замена абонентской трубки в квартире",
    ]);
    const fizVideoArray = ref([
      "Проектирование систем наблюдения с учетом особенностей объекта",
      "Монтаж и настройка оборудования",
      "Установка видеонаблюдения в частный дом или на дачу",
    ]);
    const jurMountingArray = ref([
      "Монтаж слаботочной сети: телевидение, телефония, интернет, домофонизация, системы пожарной и охранной сигнализации, автоматизированного учета энергоресурсов, локальные компьютерные сети, системы видеонаблюдения, диспетчеризация лифтового хозяйства",
      "Подбор оборудования, проектирование систем домофонизации / видеонаблюдения",
      "Монтаж и настройка аудио/видео домофонов",
      "Видеонаблюдение для офиса",
    ]);
    const fizUsers = () => {
      fizUser.value = !fizUser.value;
    };
    const jurUsers = () => {
      jurUser.value = !jurUser.value;
    };
    return {
      fizUser,
      jurUser,
      fizUsers,
      jurUsers,
      fizMounting,
      fizVideo,
      jurMounting,
      fizMountingArray,
      fizVideoArray,
      jurMountingArray,
    };
  },
};
</script>
<style>
.services_menu-item {
  color: rgb(12, 122, 141);
}
.list {
  list-style: none;
  /* margin: 0; */
  padding-left: 0;
}
.services_list {
  transition: visible 1s ;
  text-align: justify;
  /* margin-top: 10px;
  margin-left: 50px;
  margin-right: 50px; */
  border: 1px solid rgba(128, 128, 128, 0.349);
  border-radius: 5px;
  box-shadow: 1px 1px 10px gray;
}
.services_menu-item {
  cursor: pointer;
}
.services_menu-nav {
  /* margin-top: 10px; */
}
.fadeIn{
  opacity: 1;

  visibility: visible;
  transition: 0.6s;
}
.fadeOut{
  transition: 0.6s;
  opacity: 0;
  height: 0;
  /* visibility: hidden; */
  overflow: hidden;
  /* font-size: 5px; */
}
</style>
