<template>
  <div class="container">
    <div class="row mt-4">
      <p class="fs-4 fw-bold">
        ВНИМАНИЕ: Стоимость абонентской платы за техническое обслуживание
        домофонного оборудования с
        <span style="color: red; font-family: IosevkaB">01.01.2025</span> года
        составляет в г. Елец -
        <span style="color: blue; font-family: IosevkaB">37 руб.</span> в месяц,
        в пос. Газопровод -
        <span style="color: blue; font-family: IosevkaB">43 руб.</span> в месяц.
      </p>
      <p class="fs-4 fw-bold">
        ВНИМАНИЕ: В квитанциях с октября 2021 года и в последующие периоды
        указана стоимость за три месяца технического обслуживания домофонного
        оборудования.
      </p>
      <p class="fs-6">
        Уникальное предложение: установка IP домофонов, с функцией управления со
        смартфона, включая просмотр видеоизображения, и удаленное открытие
        двери.
      </p>
      <p class="fs-6">
        IP-домофон – это инновационное оборудование, предназначенное для
        контроля и управления доступом на объекты различного значения.В отличие
        от стандартных моделей, IP-домофон является более надежным и
        функциональным устройством. конструкции вызывной панели находится
        микрофон, миниатюрная камера, динамик, кнопка вызова, что обеспечивает
        максимальный функционал. Также в вызывных панелях может специальный
        считыватель, предназначенный для ключей и электронных карт. Вызывные
        панели могут подключаться к стандартной компьютерной сети для работы
        удаленно. Принимающим видео и аудио устройством может выступать:
        планшет, компьютер, смартфон, внутренний блок.
      </p>
      <p class="fs-6">
        Это дает возможность общаться с гостями, видеть и открывать двери в
        ситуациях, если находитесь вдали от дома, даже на другом континенте.
      </p>
      <p class="fs-6">
        В июле-августе 2021 г. было проведено тестирование оборудования для
        оказания данной услуги.В настоящий момент идет разработка экономической
        составляющей проекта.
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
