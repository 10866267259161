<template>
  <MainPage />
</template>

<script>
import MainPage from "@/components/MainPage";

export default {
  name: "App",
  components: {
    MainPage,
  },
};
</script>

<style>
html {
  background-color: rgb(11, 92, 31);
}
#app {
  /* font-family: JetBrainsMono; */
  font-family: Iosevka;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  background-color: beige;
  text-shadow: 2px 2px 7px rgba(128, 128, 128, 0.384);
  height: 100%;
}
@media screen and (max-width: 500px) {
  html,
  #footer,
  #app {
    font-size: 10px;
  }
  .component_block {
    /* position: relative; */
    margin: 70px 0 80px 0;
    /* height: 100%; */
    z-index: 1;
  }
}
</style>
