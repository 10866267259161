<template>
  <div>
    <div class="contract_wrapper container mt-4 ">
      <div class="row">
        <p class="fs-6">
          ООО «Импульс» заключает договор на установку / техническое
          обслуживание только с собственниками жилых помещений. Такая позиция
          полностью соответствует нормам закона, поскольку домофонное
          оборудование относится к общей собственности жильцов многоквартирного
          дома.
        </p>
        <p class="fs-6">
          Для заключения договора необходимо обратиться в офис ООО «Импульс»,
          предъявив паспорт и документ, подтверждающий право собственности на
          жилое помещение.
        </p>
      </div>
      <p class="fs-5 fw-bold">
        Обратите внимание, сведений из паспорта о регистрации по конкретному
        адресу, недостаточно.
      </p>
    </div>
  </div>
</template>
<style>
.contract_wrapper {
  /* text-align: justify; */
  /* margin: 50px; */
}
</style>
