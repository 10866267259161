<template>
  <div class="keys_wrapper container mt-4">
    <div class="row">
      <p class="fs-6">
        Абоненты ООО «Импульс» могут заказать изготовление ключей, обратившись в
        офис, и предъявив паспорт (для подтверждения регистрации по адресу, на
        который заказываются ключи) или документ, подтверждающий право
        собственности на жилое помещение.
      </p>
      <p class="fs-6">
        Мы понимаем, что такая процедура занимает время, однако, обращаем
        внимание, что домофонное оборудование относится к системам охраны, в
        связи с чем для изготовления ключей наши сотрудники должны убедиться в
        том, что вы проживаете в жилом помещении или оно принадлежит вам на
        праве собственности.
      </p>
      <p class="fs-6">
        Срок исполнения заказа три рабочих дня, не считая дня подачи заявки.
      </p>
      <p class="fs-6">
        Готовые домофонные ключи или брелки для домофона можно получить в нашем
        офисе, предъявив паспорт.
      </p>
      <p class="fs-6">
        Цену (стоимость) ключа узнавайте у диспетчера при заказе домофонных
        ключей.
      </p>
    </div>
  </div>
</template>
<style>
.keys_wrapper {
  text-align: justify;
  /* margin: 50px; */
}
</style>
